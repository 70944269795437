import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala";
import "@fontsource/sora";

const GlobalStyles = createGlobalStyle`

${
  "" /* 
*{
    outline: 1px solid red !important;
} */
}


*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    // font-family: 'Sora', sans-serif;
    font-family: 'Graphik', sans-serif;
    src: local('Graphik'), url(./assets/fonts/GraphikRegular.otf) format('opentype');
    overflow-x: hidden;
}
@media (max-width: 90.625em) { 
   html, body{
        overflow-x: hidden !important;
    }
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}
`;

export default GlobalStyles;
